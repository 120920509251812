export const initTabbedContent = (blockWrapper) => {
    const allContentElements = blockWrapper.querySelectorAll(
        ".tabbed-content__body"
    );
    const allNavbarElements = blockWrapper.querySelectorAll(".navbar__text");

    if (!allContentElements || !allNavbarElements) {
        return;
    }

    Array.from(allNavbarElements).forEach((navbarElement) => {
        navbarElement.addEventListener("click", () => {
            [...allContentElements, ...allNavbarElements].forEach((item) => {
                item.dataset.active =
                    item.dataset.index === navbarElement.dataset.index;
            });
        });
    });
};

const setupTabbedContent = () => {
    const blocks = document.querySelectorAll(".tabbed-content");
    blocks?.forEach(initTabbedContent);
};

setupTabbedContent();
