/**
 * Display the indicator for swiping through the post list on mobile
 */
const postRows = document.querySelectorAll(".post-row");

const initBlogCarouselIndicators = (block) => {
    const carouselIndicators = block.nextElementSibling;

    // only continue if the next element of the post row is the carousel
    if (
        !carouselIndicators ||
        !carouselIndicators.classList.contains("post-row-mobile-carousel")
    ) {
        return;
    }

    // only continue if there are posts
    const postCards = Array.from(block.querySelectorAll(".post-card"));
    if (postCards.length === 0) {
        return;
    }

    // Set index to the first post in the array
    let currentIndex = postCards[0].getAttribute("data-index");
    postCards.forEach((post) => {
        const indicator = document.createElement("div");
        const index = post.getAttribute("data-index");
        indicator.className =
            index === currentIndex
                ? "carousel__indicator carousel__indicator--active"
                : "carousel__indicator";
        indicator.dataset.index = index;
        carouselIndicators.append(indicator);
    });

    const observer = new IntersectionObserver(
        (entries) => {
            // find the entry with the largest intersection ratio
            const activated = entries.reduce((max, entry) => {
                return entry.intersectionRatio > max.intersectionRatio
                    ? entry
                    : max;
            });
            const targetIndex = activated.target.getAttribute("data-index");
            if (
                activated.intersectionRatio > 0 &&
                currentIndex !== targetIndex
            ) {
                getIndicatorByIndex(currentIndex).classList =
                    "carousel__indicator";

                currentIndex = targetIndex;
                getIndicatorByIndex(currentIndex).classList =
                    "carousel__indicator carousel__indicator--active";
            }
        },
        {
            threshold: 0.5,
        }
    );

    postCards.forEach((post) => {
        observer.observe(post);
    });

    const getIndicatorByIndex = (attr) => {
        return carouselIndicators.querySelector(`[data-index="${attr}"]`);
    };
};

postRows.forEach((item) => {
    initBlogCarouselIndicators(item);
});
