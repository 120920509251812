export const initOpenJobPositions = (blockWrapper) => {
    const openJobPositionsList = blockWrapper.querySelector(
        ".open-job-positions__list"
    );
    if (!openJobPositionsList) return;

    const seeMoreButton = blockWrapper.querySelector(
        ".open-job-positions__see-more"
    );

    if (!seeMoreButton) {
        return;
    }

    seeMoreButton.addEventListener("click", (event) => {
        openJobPositionsList.classList.toggle(
            "open-job-positions__list--closed"
        );
    });
};

const setupOpenJobPositions = () => {
    const blocks = document.querySelectorAll(".open-job-positions");
    blocks?.forEach(initOpenJobPositions);
};

setupOpenJobPositions();
