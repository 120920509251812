export const initHeroWideImage = (blockWrapper) => {
    const tabItemEls = blockWrapper.querySelectorAll(
        ".hero-wide-image__tabs .tab__item"
    );

    // on mobile: check which tab item is scrolled into view to display the corresponding image
    const observerCallback = (entries) => {
        const mobileBreakpoint = 1200; // @todo: move this to a global variable
        if (window.innerWidth > mobileBreakpoint) {
            return;
        }

        const selectedEl = entries.reduce((max, entry) => {
            return entry.intersectionRatio > max.intersectionRatio
                ? entry
                : max;
        });

        if (selectedEl.intersectionRatio > 0) {
            const selectedIndex = selectedEl.target.getAttribute("data-index");
            const wrapperEl = selectedEl.target.parentNode.parentNode;
            setHeroCurrentElement(wrapperEl, selectedIndex);
        }
    };
    const observerOption = {
        threshold: 0.75,
    };
    const observer = new IntersectionObserver(observerCallback, observerOption);

    // on desktop: show correct featured image item when a corresponding tab item is hovered
    tabItemEls.forEach((el) => {
        observer.observe(el);
        el.addEventListener("mouseenter", (event) => {
            const wrapperEl = event.target.parentNode.parentNode;
            const selectedIndex = event.target.getAttribute("data-index");

            setHeroCurrentElement(wrapperEl, selectedIndex);
        });
    });
};

const setHeroCurrentElement = (wrapperEl, selectedIndex) => {
    const featuredItemEls = wrapperEl.querySelectorAll(".featured__item");
    featuredItemEls.forEach((item) => {
        const itemIndex = item.getAttribute("data-index");
        if (itemIndex === selectedIndex) {
            item.classList.add("featured__item--current");
        } else {
            item.classList.remove("featured__item--current");
        }
    });
};

const setupHeroWideImages = () => {
    const blocks = document.querySelectorAll(".hero-wide-image");
    blocks?.forEach(initHeroWideImage);
};

setupHeroWideImages();
