import { initAccordion } from "../accordions/script";

const initGalleryInteraction = (blockWrapper) => {
    if (!blockWrapper) {
        return;
    }

    const galleryWrapper = blockWrapper.querySelector(
        ".product-intro__gallery"
    );
    if (!galleryWrapper) {
        return;
    }

    // nav button left
    const navButtonLeft = galleryWrapper.querySelector(".gallery__nav--left");
    navButtonLeft?.addEventListener("click", () => {
        const selectedImg = blockWrapper.querySelector(
            ".gallery__highlight img.selected"
        );
        const selectedIndex = selectedImg?.dataset.index
            ? parseInt(selectedImg?.dataset.index)
            : 0;

        let targetIndex = selectedIndex - 1;
        if (targetIndex < 0) {
            const galleryImgCount = blockWrapper.querySelectorAll(
                ".gallery__highlight img"
            ).length;
            targetIndex = galleryImgCount - 1;
        }
        setSelectedGalleryImage(blockWrapper, targetIndex);
    });

    // nav button right
    const navButtonRight = galleryWrapper.querySelector(".gallery__nav--right");
    navButtonRight?.addEventListener("click", () => {
        const selectedImg = blockWrapper.querySelector(
            ".gallery__highlight img.selected"
        );
        const selectedIndex = selectedImg?.dataset.index
            ? parseInt(selectedImg?.dataset.index)
            : 0;

        const galleryImgCount = blockWrapper.querySelectorAll(
            ".gallery__highlight img"
        ).length;

        const targetIndex =
            selectedIndex === galleryImgCount - 1 ? 0 : selectedIndex + 1;
        setSelectedGalleryImage(blockWrapper, targetIndex);
    });

    // nav dots
    blockWrapper.querySelectorAll(".gallery__dot").forEach((dot) => {
        dot.addEventListener("click", () => {
            const dotIndex = dot.dataset.index;
            setSelectedGalleryImage(blockWrapper, dotIndex);
        });
    });

    // thumbnail images
    blockWrapper
        .querySelectorAll(".gallery__thumbnails img")
        .forEach((thumbnail) => {
            thumbnail.addEventListener("click", () => {
                const thumbnailIndex = thumbnail.dataset.index;
                setSelectedGalleryImage(blockWrapper, thumbnailIndex);
            });
        });
};

const setSelectedGalleryImage = (blockWrapper, selectedIndex) => {
    // change highlight image status
    blockWrapper
        .querySelectorAll(".gallery__highlight img")
        .forEach((image) => image.classList.remove("selected"));
    blockWrapper
        .querySelector(`.gallery__highlight img[data-index="${selectedIndex}"]`)
        .classList.add("selected");

    // change highlight dot status
    blockWrapper
        .querySelectorAll(".gallery__dot")
        .forEach((dot) => dot.classList.remove("selected"));
    blockWrapper
        .querySelector(`.gallery__dot[data-index="${selectedIndex}"]`)
        .classList.add("selected");
};

export const initProductIntro = (blockWrapper) => {
    initGalleryInteraction(blockWrapper);
    initAccordion(blockWrapper);
};

const setupProductIntros = () => {
    const blocks = document.querySelectorAll(".product-intro");
    blocks?.forEach(initProductIntro);
};

setupProductIntros();
