import { formatCalculatorOutput } from "../../../assets/src/utils";

export const initCo2Calculator = (blockWrapper) => {
    const inputs = blockWrapper.querySelectorAll(
        ".co2-calculator__input-number"
    );

    inputs.forEach((input) => {
        // select the whole number in the input on focus
        input.addEventListener("focus", (event) => {
            event.target.select();
        });

        input.addEventListener("input", (event) => {
            const minValue = 1;
            const maxValue = event.target.getAttribute("max");

            let newValue = parseInt(event.target.value);
            // input validation
            if (maxValue && newValue > maxValue) {
                newValue = maxValue;
            }
            // render blank result for invalid input
            if (isNaN(newValue) || newValue < minValue) {
                renderBlankResult(blockWrapper);
                return;
            }

            // update yearly working day when monthly working day changed
            const inputField = event.target.getAttribute("data-field");
            const monthlyWorkingDayCountInput = blockWrapper.querySelector(
                ".co2-calculator__input-number[data-field=monthlyWorkingDayCount]"
            );
            const yearlyWorkingDayCountInput = blockWrapper.querySelector(
                ".co2-calculator__input-number[data-field=yearlyWorkingDayCount]"
            );
            if (inputField === "yearlyWorkingDayCount") {
                monthlyWorkingDayCountInput.value = (newValue / 12).toFixed(1);
            }
            if (inputField === "monthlyWorkingDayCount") {
                yearlyWorkingDayCountInput.value = newValue * 12;
            }

            const data = {
                userCount: blockWrapper.querySelector(
                    ".co2-calculator__input-number[data-field=userCount]"
                ).value,
                dailyHandwashCount: blockWrapper.querySelector(
                    ".co2-calculator__input-number[data-field=dailyHandwashCount]"
                ).value,
                yearlyWorkingDayCount: yearlyWorkingDayCountInput.value,
            };
            updateCalculatorResult(data, blockWrapper);
        });
    });
};

const updateCalculatorResult = (data, wrapperEl) => {
    const CO2_PER_HANDWASH = 8.5;

    const yearlyUserHandwashBox = wrapperEl.querySelector(
        "[data-field=yearlyUserHandwash] .cell__value"
    );

    yearlyUserHandwashBox.innerHTML = formatCalculatorOutput(
        data.dailyHandwashCount * data.yearlyWorkingDayCount
    );

    const totalHandwashBox = wrapperEl.querySelector(
        "[data-field=totalHandwash] .cell__value"
    );
    totalHandwashBox.innerHTML = formatCalculatorOutput(
        data.userCount * data.dailyHandwashCount * data.yearlyWorkingDayCount
    );

    const totalCo2Box = wrapperEl.querySelector(
        "[data-field=totalCo2] .cell__value"
    );
    totalCo2Box.innerHTML = formatCalculatorOutput(
        (data.userCount *
            data.dailyHandwashCount *
            data.yearlyWorkingDayCount *
            CO2_PER_HANDWASH) /
            1000,
        { isFloat: true }
    );
};

const renderBlankResult = (wrapperEl) => {
    const yearlyUserHandwashBox = wrapperEl.querySelector(
        "[data-field=yearlyUserHandwash] .cell__value"
    );
    yearlyUserHandwashBox.innerHTML = "-";

    const totalHandwashBox = wrapperEl.querySelector(
        "[data-field=totalHandwash] .cell__value"
    );
    totalHandwashBox.innerHTML = "-";

    const totalCo2Box = wrapperEl.querySelector(
        "[data-field=totalCo2] .cell__value"
    );
    totalCo2Box.innerHTML = "-";
};

const setupCalculator = () => {
    const blocks = document.querySelectorAll(".co2-calculator");
    blocks?.forEach(initCo2Calculator);
};

setupCalculator();
