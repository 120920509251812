import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const initHistorySlider = (blockWrapper) => {
    if (!blockWrapper) {
        return;
    }
    // Prevent errors if there is no carousel on the page
    const carousel = blockWrapper.querySelector(".splide");
    if (!carousel) return;

    // Get both sides of the heartline to be used later
    const heartlineLeft = blockWrapper.querySelector(".heartline--left");
    const heartlineRight = blockWrapper.querySelector(".heartline--right");

    const historySlider = new Splide(`#${blockWrapper.id} .splide`, {
        type: "carousel",
        height: "450px",
        focus: "center",
        speed: 500,
        gap: "25px",
        pagination: false,
        drag: true,
        autoWidth: true,
        start: 0,
        lazyLoad: "nearby",
    });

    historySlider.on("mounted", () => {
        // Set index 0 as starting point
        const carouselItems = blockWrapper.querySelectorAll(".carousel__item");
        carouselItems[0].classList.add("carousel__item--active");

        carouselItems.forEach((item) => {
            item.addEventListener("mouseenter", (e) => {
                if (!e.target.classList.contains("is-active")) {
                    e.target.classList.add("carousel__item--active");
                }
            });

            item.addEventListener("mouseleave", (e) => {
                if (!e.target.classList.contains("is-active")) {
                    e.target.classList.remove("carousel__item--active");
                }
            });
        });

        // Set inital position of the heartline
        heartlineLeft.style.width = "0px";
        heartlineLeft.style.left = "0px";
        heartlineRight.style.left = "300px";
        heartlineRight.style.width = `${carouselItems.length * 325}px`;
    });

    historySlider.mount();

    historySlider.on("move", (newIndex, prevIndex) => {
        const prevText = blockWrapper.querySelector(
            `.carousel__item[data-index='${prevIndex}']`
        );
        const newText = blockWrapper.querySelector(
            `.carousel__item[data-index='${newIndex}']`
        );
        prevText.classList.remove("carousel__item--active");
        newText.classList.add("carousel__item--active");

        const slideItems = blockWrapper.querySelectorAll(".carousel__item");
        const slideAmount = slideItems.length - 1;

        const remainingItems = slideAmount - newIndex;
        heartlineLeft.style.width = `${325 * newIndex}px`;
        heartlineLeft.style.left = `${325 * -newIndex}px`;
        heartlineRight.style.width = `${remainingItems * 325}px`;
    });

    // Hide navigation arrows as they will be clicked within js only
    const nextSlide = blockWrapper.querySelector(".splide__arrow--next");
    if (nextSlide) {
        nextSlide.style.display = "none";
    }

    const prevSlide = blockWrapper.querySelector(".splide__arrow--prev");
    if (prevSlide) {
        prevSlide.style.display = "none";
    }

    const navButtons = blockWrapper.querySelectorAll(".navigation__button");

    if (navButtons) {
        navButtons.forEach((button) => {
            button.addEventListener("click", () => {
                moveDirection(
                    button.getAttribute("data-direction"),
                    nextSlide,
                    prevSlide
                );
            });
        });
    }
};

const moveDirection = (direction, nextSlide, prevSlide) => {
    if (direction === "right") {
        nextSlide.click();
    } else {
        prevSlide.click();
    }
};

const setupHistorySlider = () => {
    const blocks = document.querySelectorAll(".history-slider");
    blocks?.forEach(initHistorySlider);
};

setupHistorySlider();
