export const initLocalSitePicker = (blockWrapper) => {
    const sitePicker = blockWrapper.querySelector(".dropdown select");

    if (!sitePicker) return;

    sitePicker.addEventListener("change", (e) => {
        if (e.target.value !== "") {
            window.location.href = e.target.value;
        }
    });
};

const setupLocalSitePickers = () => {
    const blocks = document.querySelectorAll(".local-site-picker");
    blocks?.forEach(initLocalSitePicker);
};

setupLocalSitePickers();
