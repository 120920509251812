export const initOfficeList = (blockWrapper) => {
    const button = blockWrapper.querySelector(".office-list__button button");
    button.addEventListener("click", (item) => {
        const visibleClass = "office-list__offices--visible";
        const officeList = blockWrapper.querySelector(
            ".office-list__offices"
        ).classList;
        const buttonIcon = button.querySelector(".button__icon");
        const buttonText = button.querySelector(".button__text");

        if (officeList.contains(visibleClass)) {
            officeList.remove(visibleClass);
            buttonIcon.innerHTML = "expand_more";
            buttonText.innerHTML = "Show all offices";
        } else {
            officeList.add(visibleClass);
            buttonIcon.innerHTML = "expand_less";
            buttonText.innerHTML = "Hide all offices";
        }
    });
};

const setupOfficeList = () => {
    const blocks = document.querySelectorAll(".office-list");
    blocks?.forEach(initOfficeList);
};
setupOfficeList();
