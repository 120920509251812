export const initAccordionTeasers = (blockWrapper) => {
    const accordionItems = blockWrapper.querySelectorAll(
        ".accordion-teaser .accordion-teaser__item"
    );

    const accordionButtons = blockWrapper.querySelectorAll(
        ".accordion-teaser .content__button button"
    );

    if (accordionItems.length !== 0) {
        // Setup the initial expanded item
        let currentlyOpen = accordionItems[0];
        currentlyOpen.classList.add("accordion-teaser__item--active");
        currentlyOpen
            .querySelector(".item__content")
            .classList.add("item__content--active");

        openDescription(currentlyOpen);
        setButtonContent(currentlyOpen);

        // Setup eventlisteners for all 3 buttons
        Array.from(accordionButtons).forEach((button) => {
            button.addEventListener("click", () => {
                // Get parent teaser item
                const newAccordion = button.closest(".accordion-teaser__item");
                if (
                    newAccordion.classList.contains(
                        "accordion-teaser__item--active"
                    )
                ) {
                    // If the same item is clicked again, look for a sibling element to open instead
                    const nextSibling =
                        newAccordion.nextElementSibling ??
                        newAccordion.previousElementSibling;

                    triggerAccordion(currentlyOpen, nextSibling);
                    currentlyOpen = nextSibling;
                } else {
                    triggerAccordion(currentlyOpen, newAccordion);
                    currentlyOpen = newAccordion;
                }
            });
        });
    }
};

const triggerAccordion = (currentlyOpen, newAccordion) => {
    currentlyOpen.classList.remove("accordion-teaser__item--active");
    currentlyOpen
        .querySelector(".item__content")
        .classList.remove("item__content--active");

    newAccordion
        .querySelector(".item__content")
        .classList.add("item__content--active");
    newAccordion.classList.add("accordion-teaser__item--active");

    setButtonContent(newAccordion, currentlyOpen);

    closeDescription(currentlyOpen);
    openDescription(newAccordion);
};

const setButtonContent = (nextContent, currentContent) => {
    // Needs to check on initial load since there is no currentContent on load
    if (currentContent) {
        const currentButtonIcon = currentContent.querySelector(".button__icon");
        currentButtonIcon.innerHTML = "add";
    }

    const nextButtonIcon = nextContent.querySelector(".button__icon");
    nextButtonIcon.innerHTML = "remove";
};

const closeDescription = (currentlyOpen) => {
    currentlyOpen
        .querySelector(".description__full")
        .classList.remove("description__full--visible");
    currentlyOpen
        .querySelector(".description__short")
        .classList.add("description__short--visible");
};

const openDescription = (newAccordion) => {
    newAccordion
        .querySelector(".description__full")
        .classList.add("description__full--visible");
    newAccordion
        .querySelector(".description__short")
        .classList.remove("description__short--visible");
};

const setupAccordionTeasers = () => {
    const blocks = document.querySelectorAll(".accordion-teaser");
    blocks?.forEach(initAccordionTeasers);
};
setupAccordionTeasers();
