const initEshopHeroes = (blockWrapper) => {
    const employeesAmountInput = blockWrapper.querySelector('.employees-amount')
    const eshopLinkElement = blockWrapper.querySelector('.eshop-link')
    let originalUrl = eshopLinkElement.getAttribute('href')

    // Strip the slash if there's one in the original url
    if (originalUrl.endsWith('/')) {
        originalUrl = originalUrl.slice(0, -1)
    }

    employeesAmountInput.addEventListener('input', e => {
        const value = employeesAmountInput.value
        const sanitizedValue = value.replace(/[^0-9]/g, '')

        employeesAmountInput.value = sanitizedValue

        const employeesAmount = e.target.value

        if (employeesAmount) {
            const newUrl = `${originalUrl}?employees=${employeesAmount}`
            eshopLinkElement.setAttribute('href', newUrl)
        } else {
            eshopLinkElement.setAttribute('href', originalUrl)
        }
    })
}

const setupEshopHeroes = () => {
    const eshopHeroWrappers = document.querySelectorAll('.eshop-hero')
    eshopHeroWrappers?.forEach(initEshopHeroes)
}

setupEshopHeroes()
