const initSingleStaff = () => {
    const heroImage = document.querySelector(".staff-single .hero__image");
    if (!heroImage) return;
    const primary = heroImage.querySelector(".image--primary");
    const secondary = heroImage.querySelector(".image--secondary");

    if (primary && secondary) {
        heroImage.addEventListener("mouseenter", () => {
            primary.style.opacity = 0;
            secondary.style.opacity = 1;
        });
        heroImage.addEventListener("mouseleave", () => {
            primary.style.opacity = 1;
            secondary.style.opacity = 0;
        });
    }
};

initSingleStaff();
