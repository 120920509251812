export const initCountrySelectorDropdown = (blockWrapper) => {
    const countrySelectorDropdown = blockWrapper.querySelector(
        ".country-selector-dropdown__container select"
    );

    if (!countrySelectorDropdown) return;

    countrySelectorDropdown.addEventListener("change", (e) => {
        if (e.target.value !== "") {
            window.location.href = e.target.value;
        }
    });
};

const setupCountrySelectorDropdowns = () => {
    const blocks = document.querySelectorAll(".country-selector-dropdown");
    blocks?.forEach(initCountrySelectorDropdown);
};

setupCountrySelectorDropdowns();
