export const initExpandableContent = (blockWrapper) => {
    const expandableContentItems = blockWrapper.querySelectorAll(
        ".expandable-content-with-image .expandable-content__item"
    );

    const expandableContentButtons = blockWrapper.querySelectorAll(
        ".expandable-content-with-image .item__button"
    );

    if (expandableContentItems.length !== 0) {
        // Setup eventlisteners for all 3 buttons
        Array.from(expandableContentButtons).forEach((button) => {
            button.addEventListener("click", () => {
                // Get parent teaser item
                const parentElement = button.closest(
                    ".expandable-content__item"
                );
                triggerExpandableContent(parentElement);
            });
        });
    }
};

const triggerExpandableContent = (expandableContent) => {
    if (
        expandableContent.classList.contains("expandable-content__item--active")
    ) {
        expandableContent.classList.remove("expandable-content__item--active");
        expandableContent
            .querySelector(".item__long")
            .classList.remove("item__long--visible");
        expandableContent
            .querySelector(".item__short")
            .classList.add("item__short--visible");
        setButtonContent(expandableContent, false);
    } else {
        expandableContent.classList.add("expandable-content__item--active");
        expandableContent
            .querySelector(".item__long")
            .classList.add("item__long--visible");
        expandableContent
            .querySelector(".item__short")
            .classList.remove("item__short--visible");

        setButtonContent(expandableContent, true);
    }
};

const setButtonContent = (expandableContent, isOpen) => {
    const button = expandableContent.querySelector(".item__button button");
    const buttonLabel = button.querySelector(".label");
    const buttonIcon = button.querySelector(".material-symbols-outlined");
    const label = isOpen
        ? button.getAttribute("data-close")
        : button.getAttribute("data-open");
    const icon = isOpen ? "remove" : "add";
    buttonLabel.innerHTML = label;
    buttonIcon.innerHTML = icon;
};

const setupExpandableContent = () => {
    const blocks = document.querySelectorAll(".expandable-content-with-image");
    blocks?.forEach(initExpandableContent);
};
setupExpandableContent();
