import ButtonBackIcon from "../../../assets/img/button-back.svg";
import ButtonCloseIcon from "../../../assets/img/button-close.svg";

const addMobileMainMenuControl = () => {
    const mobileHeaderEl = document.querySelector("#ast-mobile-header");
    if (!mobileHeaderEl) {
        return;
    }

    // open the corresponding sub menu when clicking the menu link on mobile
    const mobileMainMenuItems = mobileHeaderEl.querySelectorAll(
        "ul.main-header-menu > li > a"
    );
    mobileMainMenuItems.forEach((mainMenuItem) => {
        mainMenuItem.addEventListener("click", (event) => {
            console.log(event.target);
            // trigger the click event of the sub menu button next to the selected item
            if (event.target.nextSibling) {
                event.preventDefault();
                event.target.nextSibling.click();
            } else {
                event.target.click();
            }
        });
    });
};

const addMobileSubmenuControl = () => {
    const mobileHeaderEl = document.querySelector("#ast-mobile-header");

    if (!mobileHeaderEl) {
        return;
    }

    // create a "fake" back menu and add to the top left of an opening sub menu
    const subMenuBackButton = document.createElement("button");
    subMenuBackButton.className =
        "mobile-sub-menu-fake-button mobile-sub-menu-fake-button--back";
    subMenuBackButton.innerHTML = `<img src="${ButtonBackIcon}" />`;
    mobileHeaderEl.prepend(subMenuBackButton);

    // trigger submenu toggle event
    subMenuBackButton.addEventListener("click", AstraToggleSubMenu);
    subMenuBackButton.addEventListener("click", toggleSubmenuCloseButtonColor);

    // create a "fake" close menu and add to the top right of an opening sub menu
    const subMenuCloseButton = document.createElement("button");
    subMenuCloseButton.className =
        "mobile-sub-menu-fake-button mobile-sub-menu-fake-button--close mobile-sub-menu-fake-button--inverted-color";
    subMenuCloseButton.innerHTML = `<img src="${ButtonCloseIcon}" />`;
    mobileHeaderEl.prepend(subMenuCloseButton);

    subMenuCloseButton.addEventListener("click", () => {
        document
            .querySelector(".mobile-sub-menu-fake-button--close")
            .classList.add("mobile-sub-menu-fake-button--inverted-color");
        // find the menu toggle button and trigger it when the fake button is clicked
        document.querySelector(".menu-toggle.toggled")?.click();
    });
};

const addSubmenuToggleListener = () => {
    const subMenuToggleEls = document.querySelectorAll(
        "#ast-mobile-header .ast-menu-toggle"
    );
    Array.from(subMenuToggleEls).forEach((subMenuToggleEl) => {
        subMenuToggleEl.addEventListener(
            "click",
            toggleSubmenuCloseButtonColor
        );
    });
};

/**
 * On mobile display the close button as white on dark background, and black on white background
 */
const toggleSubmenuCloseButtonColor = () => {
    const subMenuCloseButton = document.querySelector(
        ".mobile-sub-menu-fake-button--close"
    );

    if (!subMenuCloseButton) {
        return;
    }

    subMenuCloseButton.classList.toggle(
        "mobile-sub-menu-fake-button--inverted-color"
    );
};

/**
 * Apply the submenu's color corresponding to the one set in the top level menu
 */
const fillSubmenuBackgroundColor = () => {
    // only affect second level menu
    const expandableTopLevelMenuEls = document.querySelectorAll(
        ".main-header-menu > .menu-item-has-children"
    );

    expandableTopLevelMenuEls.forEach((item) => {
        const colorClassPrefix = "sub-menu-bg-";
        const colorClass = Array.from(item.classList).find((className) =>
            className.startsWith(colorClassPrefix)
        );
        if (!colorClass) {
            return;
        }

        // painting sub menu background color
        const colorHex = `#${colorClass.substring(colorClassPrefix.length)}`;
        const subMenuEl = item.querySelector(".sub-menu");
        if (subMenuEl) {
            subMenuEl.style.backgroundColor = colorHex;
        }
    });
};

addMobileMainMenuControl();
addMobileSubmenuControl();
addSubmenuToggleListener();
fillSubmenuBackgroundColor();
