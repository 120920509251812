export const initStaffListCards = (blockWrapper) => {
    const cards = blockWrapper.querySelectorAll(
        ".staff-list .staff-list__card"
    );
    Array.from(cards).forEach((item) => {
        const primaryImage = item.querySelector(".image--primary");
        const secondaryImage = item.querySelector(".image--secondary");
        if (primaryImage && secondaryImage) {
            item.addEventListener("mouseenter", () => {
                primaryImage.style.opacity = 0;
                secondaryImage.style.opacity = 1;
            });
            item.addEventListener("mouseleave", () => {
                primaryImage.style.opacity = 1;
                secondaryImage.style.opacity = 0;
            });
        }

        const detailTrigger = item.querySelector(".read-more__trigger");
        detailTrigger?.addEventListener("click", () => {
            detailTrigger.classList.toggle("read-more__trigger--open");
            const detailContent = item.querySelector(".read-more__content");
            if (!detailContent) {
                return;
            }
            detailContent.classList.toggle("hidden");
        });
    });
};

const setupStaffListCards = () => {
    const blocks = document.querySelectorAll(".staff-list");
    blocks?.forEach(initStaffListCards);
};

setupStaffListCards();
