import { CountUp } from "countup.js";

export const initStatisticsAnimation = (blockWrapper) => {
    // IntersectionObserver checks if the selected element had been brought into the viewport and runs the countUp once it's been found
    // This will only run once
    const statisticsObserver = new IntersectionObserver(
        (entries, obs) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting === true) {
                    obs.unobserve(entry.target);
                    const targets =
                        entry.target.querySelectorAll(".statistic__number");
                    targets.forEach((target) => {
                        const targetNumber = target.innerHTML;
                        countNumbers(target, targetNumber);
                    });
                }
            });
        },
        { threshold: [0.4] }
    );
    statisticsObserver.observe(blockWrapper);
};

const countNumbers = (target, targetNumber) => {
    // User might input , as decimal separator (default is .)
    targetNumber = parseFloat(targetNumber.toString().replace(",", "."));

    const options = {
        decimalPlaces: getDecimalAmount(targetNumber),
        startVal: 0,
        useGrouping: false,
    };
    const countUp = new CountUp(target, targetNumber, options);
    if (!countUp.error) {
        countUp.start();
    } else {
        console.error(countUp.error);
    }
};

const getDecimalAmount = (targetNumber) => {
    if (targetNumber % 1 === 0) {
        return 0;
    }

    const decimals = targetNumber.toString().split(".")[1];
    return decimals ? decimals.length : 0;
};

const setupStatisticsAnimation = () => {
    const blocks = document.querySelectorAll(".statistics");
    // Loop through all statistics blocks on the page and attach a observer to each of them
    blocks?.forEach(initStatisticsAnimation);
};

setupStatisticsAnimation();
