export const initAccordion = (blockWrapper) => {
    const accordionHeaders = blockWrapper.querySelectorAll(".item__header");
    if (!accordionHeaders) return;

    Array.from(accordionHeaders).forEach((accordion) => {
        const contentBox = accordion.nextElementSibling;
        contentBox.classList.remove("item__content--open");

        if (accordion.getAttribute("data-event-added")) {
            return;
        }

        accordion.addEventListener("click", () => {
            const headerIcon = accordion.querySelector(".header__icon");

            if (contentBox.classList.contains("item__content--open")) {
                contentBox.classList.remove("item__content--open");
                headerIcon.innerHTML = "add";
            } else {
                contentBox.classList.add("item__content--open");
                headerIcon.innerHTML = "remove";
            }
        });
        accordion.setAttribute("data-event-added", "true");
    });
};

const setupAccordions = () => {
    const blocks = document.querySelectorAll(".accordions");
    blocks?.forEach(initAccordion);
};

setupAccordions();
