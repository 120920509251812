const addCountrySelectorOpenControl = () => {
    const mobileHeaderEl = document.querySelector(
        "#ast-mobile-header .topnav-country-selector .menu-item"
    );
    if (!mobileHeaderEl) {
        return;
    }
    mobileHeaderEl.addEventListener("click", (e) => {
        const htmlBody = document.querySelector("body");
        htmlBody.classList.add("topnav-country-selector--visible");

        const subMenu = mobileHeaderEl.nextElementSibling;
        subMenu.classList.add("sub-menu--expanded");
    });
};

const addCountrySelectorCloseControl = () => {
    const closeButton = document.querySelector(
        "#ast-mobile-header .topnav-country-selector .sub-menu__close--mobile > img"
    );
    if (!closeButton) {
        return;
    }
    closeButton.addEventListener("click", (e) => {
        const htmlBody = document.querySelector("body");
        htmlBody.classList.remove("topnav-country-selector--visible");

        const subMenu = closeButton.closest(".sub-menu");
        subMenu.classList.remove("sub-menu--expanded");
    });
};

addCountrySelectorOpenControl();
addCountrySelectorCloseControl();
