import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const initGlobalSiteHero = (blockWrapper) => {

    console.error('init hero slide for', blockWrapper)
    if (!blockWrapper || !blockWrapper.querySelector(".splide")) {
        return;
    }

    const slider = new Splide(`#${blockWrapper.id} .splide`, {
        arrows: true,
        drag: true,
        speed: 500,
        pagination: true,
        autoplay: true,
        type: "loop",
        start: 0,
        lazyLoad: "nearby",
        preloadPages: 1
    })

    // Ensure that the clone slides do not have H1 heading
    slider.on('mounted', function () {
        document.querySelectorAll('.splide__slide.splide__slide--clone [data-heading="true"]').forEach(h1 => {
            const h2 = document.createElement('h2');
            h2.innerHTML = h1.innerHTML;
            h1.parentNode.replaceChild(h2, h1);
        });
    });

    slider.mount()
};

const setupGlobalSiteHero = () => {
    const blocks = document.querySelectorAll(".global-site-hero");
    blocks?.forEach(initGlobalSiteHero);
};

setupGlobalSiteHero();
