import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const initTestimonials = (blockWrapper) => {
    if (!blockWrapper || !blockWrapper.querySelector(".splide")) {
        return;
    }

    const testimonialSlider = new Splide(`#${blockWrapper.id} .splide`, {
        type: "fade",
        drag: true,
        speed: 500,
        pagination: false,
        drag: true,
        start: 0,
        lazyLoad: "nearby",
    });

    testimonialSlider.on("mounted", () => {
        const navButtons = blockWrapper.querySelectorAll(".navigation__button");

        navButtons.forEach((button) => {
            button.addEventListener("click", () =>
                moveDirection(
                    blockWrapper,
                    button.getAttribute("data-direction")
                )
            );
        });
    });

    testimonialSlider.on("move", (newIndex) => {
        const newTestimonial = blockWrapper.querySelector(
            `.testimonial__item[data-index="${newIndex}"]`
        );

        const currentPage = newTestimonial.querySelector(
            ".navigation__numbers .number__pagenumber"
        );

        if (currentPage) {
            const pageNumber =
                newIndex.toString().length < 2
                    ? "0" + parseInt(newIndex + 1)
                    : newIndex + 1;
            currentPage.innerHTML = pageNumber;
        }
    });
    testimonialSlider.mount();
};

const moveDirection = (blockWrapper, direction) => {
    const navButton = blockWrapper.querySelector(
        `.splide__arrow--${direction}`
    );
    if (!navButton) return;
    navButton.click();
};

const setupTestimonials = () => {
    const blocks = document.querySelectorAll(".testimonials");
    blocks?.forEach(initTestimonials);
};

setupTestimonials();
