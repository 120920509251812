import { formatCalculatorOutput } from "../../../assets/src/utils";

export const initSustainabilityCalculator = (blockWrapper) => {
    const inputs = blockWrapper.querySelectorAll(
        ".sustainability-calculator__input-wrap input"
    );
    updateCalculatorResult(5, blockWrapper);
    inputs.forEach((input) => {
        input.addEventListener("input", (event) => {
            const minValue = 1;
            const maxValue = 50000;

            let newValue = event.target.value;
            // input validation
            if (isNaN(newValue)) {
                newValue = minValue;
            }
            if (newValue < minValue) {
                newValue = minValue;
            }

            // update other input's value after user edit the other one
            event.target.parentElement
                .querySelectorAll("input")
                .forEach((el) => {
                    el.value = newValue;
                });
            // update the calculator results
            updateCalculatorResult(newValue, blockWrapper);
        });
    });
};

// based on the `counter` function from the old website
const updateCalculatorResult = (garmentPcs, wrapperEl) => {
    const WASHING_PERCENT = 0.62,
        GARMENT_WEIGHT = 0.44,
        customSpec = garmentPcs * WASHING_PERCENT * GARMENT_WEIGHT;
    // water calculation
    const WATER_I_BASE = 12.05, //  Water industrial l/kg
        WATER_H_BASE = 30.3030303, //  Water home washing l/kg
        waterIMonth = WATER_I_BASE * customSpec,
        waterHMonth = WATER_H_BASE * customSpec,
        waterSaveYear = (waterHMonth - waterIMonth) * 12;
    // detergent calculation
    const DETERGENT_I_BASE = 27.77,
        DETERGENT_H_BASE = 55.55555556,
        detergentIMonth = DETERGENT_I_BASE * customSpec,
        detergentHMonth = DETERGENT_H_BASE * customSpec,
        detergentSaveYear = ((detergentHMonth - detergentIMonth) * 12) / 1000; // total unit is kg instead of g
    // energy calculation
    const ENERGY_I_BASE = 1.38,
        ENERGY_H_BASE = 2.1464646,
        energyIMonth = ENERGY_I_BASE * customSpec,
        energyHMonth = ENERGY_H_BASE * customSpec,
        energySaveYear = (energyHMonth - energyIMonth) * 12;
    // carbon footprint calculation
    const CARBON_I_BASE = 0.327,
        CARBON_H_BASE = 1.236,
        carbonIMonth = CARBON_I_BASE * customSpec,
        carbonHMonth = CARBON_H_BASE * customSpec,
        carbonSaveYear = (carbonHMonth - carbonIMonth) * 12;
    // textile waste calculation
    const TEXTILE_I_BASE = 0.0484, // 4,84%
        TEXTILE_H_BASE = 0.51, // 51%
        textileIMonth = garmentPcs * WASHING_PERCENT * TEXTILE_I_BASE,
        textileHMonth = TEXTILE_H_BASE * textileIMonth, // Thrown away pieces
        textileSaveYear = textileHMonth * GARMENT_WEIGHT * 12;

    const calculatedData = {
        waterIMonth,
        waterHMonth,
        waterSaveYear,
        detergentIMonth,
        detergentHMonth,
        detergentSaveYear,
        energyIMonth,
        energyHMonth,
        energySaveYear,
        carbonIMonth,
        carbonHMonth,
        carbonSaveYear,
        textileIMonth,
        textileHMonth,
        textileSaveYear,
    };

    // fill the value boxes with updated values
    const fieldSlugs = ["water", "detergent", "energy", "carbon", "textile"];
    fieldSlugs.forEach((field) => {
        const fieldIValueBox = wrapperEl.querySelector(
            `[data-field=${field}-industrial]`
        );
        if (fieldIValueBox) {
            fieldIValueBox.innerHTML = formatCalculatorOutput(
                calculatedData[`${field}IMonth`]
            );
        }

        const fieldHValueBox = wrapperEl.querySelector(
            `[data-field=${field}-home]`
        );
        if (fieldHValueBox) {
            fieldHValueBox.innerHTML = formatCalculatorOutput(
                calculatedData[`${field}HMonth`]
            );
        }

        const fieldSaveValueBox = wrapperEl.querySelector(
            `[data-field=${field}-save]`
        );
        if (fieldSaveValueBox) {
            fieldSaveValueBox.innerHTML = formatCalculatorOutput(
                calculatedData[`${field}SaveYear`]
            );
        }
    });
};

const setupCalculator = () => {
    const blocks = document.querySelectorAll(".sustainability-calculator");
    blocks?.forEach(initSustainabilityCalculator);
};

setupCalculator();
