import Splide from "@splidejs/splide";
import "@splidejs/splide/css";

export const initStoryCarousel = (blockWrapper) => {
    // Prevent errors if there is no carousel on the page
    const carousel = blockWrapper.querySelector(".stories .splide");
    if (!carousel) return;

    const parentContainer = blockWrapper.querySelector(
        ".stories .container__text"
    );

    const imageCarousel = new Splide(".stories .splide", {
        type: "carousel",
        height: "407px",
        focus: "center",
        speed: 500,
        gap: "25px",
        pagination: false,
        drag: true,
        autoWidth: true,
        start: 0,
    });

    imageCarousel.on("mounted", () => {
        // Set index 0 as starting point
        const textContainers = blockWrapper.querySelectorAll(
            ".stories .text--wrapper"
        );
        textContainers[0].classList.add("text--wrapper-visible");
        parentContainer.style.minHeight = `${textContainers[0].offsetHeight}px`;
    });

    imageCarousel.mount();

    imageCarousel.on("move", (newIndex, prevIndex) => {
        const prevText = blockWrapper.querySelector(
            `.stories .text--wrapper[data-index='${prevIndex}']`
        );
        const newText = blockWrapper.querySelector(
            `.stories .text--wrapper[data-index='${newIndex}']`
        );
        prevText.classList.remove("text--wrapper-visible");
        newText.classList.add("text--wrapper-visible");
        // Set height of parent text container according to content height
        parentContainer.style.minHeight = `${newText.offsetHeight}px`;
    });

    const nextSlide = blockWrapper.querySelector(
        ".stories .splide__arrow--next"
    );
    const prevSlide = blockWrapper.querySelector(
        ".stories .splide__arrow--prev"
    );

    // Hide navigation arrows as they will be clicked within js only
    nextSlide.style.display = "none";
    prevSlide.style.display = "none";

    const navButtons = blockWrapper.querySelectorAll(
        ".stories .navigation__button"
    );

    navButtons.forEach((button) => {
        button.addEventListener("click", () => {
            moveDirection(
                button.getAttribute("data-direction"),
                nextSlide,
                prevSlide
            );
        });
    });
};

const moveDirection = (direction, nextSlide, prevSlide) => {
    if (direction === "right") {
        nextSlide.click();
    } else {
        prevSlide.click();
    }
};

const setupStoryCarousel = () => {
    const blocks = document.querySelectorAll(".stories");
    blocks?.forEach(initStoryCarousel);
};

setupStoryCarousel();
