/**
 * Check if the editor ready to enable the preview for certain blocks which require js.
 * Credit: KevinBatdorf
 * Link: https://gist.github.com/KevinBatdorf/fca19e1f3b749b5c57db8158f4850eff
 */

export const whenEditorIsReady = () => {
    if (typeof wp === "undefined" || !wp.blocks) {
        return;
    }

    const { select, subscribe } = wp.data;
    return new Promise((resolve) => {
        const unsubscribe = subscribe(() => {
            if (
                select("core/editor")?.isCleanNewPost() ||
                select("core/block-editor").getBlockCount() > 0
            ) {
                unsubscribe();
                resolve();
            }
        });
    });
};

/**
 * Take an input number, round to integer and add a space as thousand separator
 * @param {Number} input
 * @param {Object} options supports:
 *      - isFloat bool - display the output as float if set to true
 */
export const formatCalculatorOutput = (input, options) => {
    if (isNaN(input)) {
        return input;
    }

    const isFloat = options?.isFloat;
    // show 1 decimal place if the value is under 10
    if (input < 10 || isFloat) {
        return Math.round(input * 10) / 10;
    }

    return Math.round(input)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};
